import styled from 'styled-components';

const Button = styled.button`
  border: none;
  background-color: #dfdfdf;
  padding: 0px 20px;
  height: 30px;
  cursor: pointer;
  outline: none;
`;

export default Button;
