import styled from 'styled-components';

const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  width: 220px;
  height: 220px;
  margin: 5px;
  border-bottom: 1px solid #ececec;

  ${({ small }) => small && `
    width: 180px;
    height: 180px;
  `}

  ${({ loading }) => loading && 'animation: loading 1s infinite alternate;'}
  @keyframes loading {
    0% {
      border: 1px solid white;
    }
    100% {
      border: 1px solid #dfdfdf;
    }
  }

  @keyframes SUCCESS {
    0%   { box-shadow: 0px 0px 30px green; }
    100% { box-shadow: inset 0px 0px 0px transparent; }
  }
`;

export const CardFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  margin: 5px 0 0 0;
`;

export default Card;
