import React from 'react';
import styled from 'styled-components';
import breakpoints from '../styles/breakpoints';
import Button from './Button';

const StyledDialog = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  display: none;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);

  z-index: 9;

  ${({ show }) => show && 'display: flex;'}
`;

const Modal = styled.div`
  width: 100%;
  max-width: 300px;
  margin: 10px;
  background-color: white;

  p {
    margin: 20px 10px 25px;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;

  > button {
    margin-left: 5px;
  }
`;

const Dialog = ({ show, message = 'Mensaje de ejemplo', onCancel, onAccept }) => (
  <StyledDialog show={show}>
    <Modal>
      <p>{message}</p>
      <Footer>
        <Button onClick={onCancel}>Cancelar</Button>
        <Button onClick={onAccept} style={{ background: 'red', color: 'white' }}>Aceptar</Button>
      </Footer>
    </Modal>
  </StyledDialog>
);

export default Dialog;
