import { BaseApi, PublicApi } from './BaseApi';

export const listSubPhotos = (location) => PublicApi.get(`/subphotos${location ? `/${location}` : ''}`);

export const addSubPhoto = (body) => BaseApi.post('/subphotos/add', body, {
  headers: { 'Content-Type': 'multipart/form-data' },
});

export const editSubPhoto = (body) => BaseApi.put('/subphotos/edit', body, {
  headers: { 'Content-Type': 'multipart/form-data' },
});

export const removeSubPhoto = (id) => BaseApi.delete(`/subphotos/delete/${id}`);
