import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { API_URL } from '../api/BaseApi';
import { debounce } from '../scripts/utils';
import Card, { CardFooter } from './Card';
import Form from './Form';

const Image = styled.div`
  width: 100%;
  height: 65%;
  background-color: #e3e3e3;
  background: url(${({ src }) => src});
  background-size: cover;
`;

const Note = styled.input.attrs(() => ({
  type: 'text',
}))`
  color: grey;
  font-weight: normal;
  text-align: center;
  margin: 0;
  padding: 0.5em;
  width: 100%;
  line-height: 1em;
  border: none;
  outline: none;

  :hover {
    box-shadow: inset 1px 1px 10px #e4e4e4;
  }

  :focus {
    box-shadow: inset 1px 1px 10px #b4b4b4;
  }
`;

const InputGroup = styled.div`
  cursor: pointer;
`;

const Check = styled.input.attrs(() => ({
  type: 'checkbox',
}))`
`;

const Label = styled.label`
  span {
    margin-right: 5px;
  }
`;

const CircleButton = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;

  color: white;
  background: red;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  border-top-right-radius: 0px;
  cursor: pointer;
  z-index: 1;
`;

const WOverlay = styled.div`
  display: none;
  position: absolute;
  background-color: #ffffff;
  opacity: 0.7;
  z-index: 799999;
  width: 100%;
  height: 100%;
  ${({ loading }) => loading && 'display: block'}
`;

const PhotoItem = ({ data, onEditPhoto, onDelete, small }) => {
  const { id, photoPath, name = '', showInHome, showInStore } = data;
  const src = `${API_URL}/${photoPath}`;
  const [note, setNote] = useState(name);
  const [isShownInHome, setIsShownInHome] = useState(showInHome);
  const [isShownInStore, setIsShownInStore] = useState(showInStore);
  const [loading, setLoading] = useState(false);
  const DELAY = 1000;

  const handleEditPhotoName = (value) => {
    const formData = new FormData();
    formData.set('id', id);
    formData.set('name', value);
    setLoading(true);
    onEditPhoto(formData);
    setLoading(false);
  };

  const handleEditPhotoVisibility = async (home, store) => {
    const formData = new FormData();
    formData.set('id', id);
    formData.set('showInHome', home);
    formData.set('showInStore', store);
    setLoading(true);
    onEditPhoto(formData);
    setLoading(false);
  };

  const debouncedEditPhotoName = useCallback(debounce(handleEditPhotoName, DELAY), []);
  const debouncedEditPhotoVisibility = useCallback(debounce(handleEditPhotoVisibility, DELAY), []);

  const handleNoteOnChange = (ev) => {
    setNote(ev.target.value);
    debouncedEditPhotoName(ev.target.value);
  };
  const handleShowInHomeOnChange = () => {
    setIsShownInHome(!isShownInHome);
    debouncedEditPhotoVisibility(!isShownInHome, isShownInStore);
  };

  const handleShowInStoreOnChange = () => {
    setIsShownInStore(!isShownInStore);
    debouncedEditPhotoVisibility(isShownInHome, !isShownInStore);
  };

  const handleRemovePhoto = () => {
    onDelete(id);
  };

  return (
    <Card loading={loading} small={small}>
      <WOverlay loading={loading} />
      <CircleButton onClick={handleRemovePhoto}>✕</CircleButton>
      <Form>
        <Image src={src} />
        <Note value={note} onChange={handleNoteOnChange} />
        <CardFooter style={{ flexDirection: 'row' }}>
          <InputGroup>
            <Label>
              <span>Inicio</span>
              <Check onChange={handleShowInHomeOnChange} checked={isShownInHome} />
            </Label>
          </InputGroup>
          <InputGroup>
            <Label>
              <span>Tienda</span>
              <Check onChange={handleShowInStoreOnChange} checked={isShownInStore} />
            </Label>
          </InputGroup>
        </CardFooter>
      </Form>
    </Card>
  );
};

export default PhotoItem;
