import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled from 'styled-components';

import Header from "./Header"
import Footer from './Footer';
import "../styles/normalize.css";
import "../styles/global.css";
import breakpoints from "../styles/breakpoints";

const StyledFlexLayout = styled.div`
  ${breakpoints.desktop} {
    display: grid;
    grid-template-columns: auto repeat(12, minmax(auto, 87px)) auto;
    grid-template-rows: auto 1fr;
    grid-column-gap: 20px;
    height: 100vh;
  }
`;

const Main = styled.main`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: 4.125em 20px;

  ${breakpoints.desktop} {
    position: relative;
    display: flex;
    flex-direction: column;
    grid-column: 2 / 14;
    margin: 0px;
  }
`;

const FlexLayout = ({ children }) => {

  return (
    <StyledFlexLayout>
      <Header siteTitle="Admin" />
      <Main>
        {children}
      </Main>
      <Footer />
    </StyledFlexLayout>
  )
}

FlexLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default FlexLayout
