import styled from 'styled-components';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`;

export const TextInput = styled.input.attrs(() => ({ type: 'text' }))`
  flex: 1;
  border: none;
  outline: none;
  border: 1px solid #dfdfdf;
  height: 40px;
  padding: 5px;
  text-align: center;

  :hover {
    border-color: #b0b0b0;
  }

  :focus {
    border-color: gray;
  }
`;


export default Form;
