import React, { useState } from 'react';
import Card, { CardFooter } from './Card';
import Form, { TextInput } from './Form';
import Dropzone from './Dropzone';
import Button from './Button';

const AddPhoto = ({ type, onSubmit, placeholder, small }) => {
  const [photo, setPhoto] = useState();
  const [picName, setPicName] = useState('');
  const [cleanPrevs, setCleanPrevs] = useState(false);

  const handleOnTextChange = (ev) => setPicName(ev.target.value);
  const handleFilesAdded = (files) => {
    setPhoto(files[0]);
    setCleanPrevs(false);
  };

  const handleOnSubmit = (ev) => {
    ev.preventDefault();
    const formData = new FormData();
    formData.set('name', picName);
    formData.set(type, photo);
    setCleanPrevs(true);
    setPicName('');
    onSubmit(formData);
  };

  return (
    <Card small={small}>
      <Form onSubmit={handleOnSubmit}>
        <Dropzone
          cleanPreviews={cleanPrevs}
          onFilesAdded={handleFilesAdded}
        >
          {placeholder}
        </Dropzone>
        <CardFooter>
          <TextInput value={picName} onChange={handleOnTextChange} />
          <Button style={{ marginTop: '5px' }}>Subir</Button>
        </CardFooter>
      </Form>
    </Card>
  );
};

export default AddPhoto;
