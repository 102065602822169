import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { useEffect } from 'react';
import { useCallback } from 'react';

const StyledDropzone = styled.div`
  background: #eaeaea;
  border: 2px dashed #a4a4a4;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 141.63px;
  cursor: pointer;
  background-image: url(${({ preview }) => preview});
  background-size: cover;

  ${({ highlight }) => highlight && `
    background: #bbbbbb;
    border: 2px dashed #5a5a5a;
  `}
`;

const StyledInput = styled.input`
  display: none;
`;

const Label = styled.p`
  margin: 0;
`;

const Dropzone = ({ children, onFilesAdded = () => {}, cleanPreviews, multiple }) => {
  const fileInputRef = useRef();
  const [highlight, setHighlight] = useState(false);
  const [previews, setPreviews] = useState([]);

  const getPreview = (file) => {
    const url = URL.createObjectURL(file);
    console.log('%c🐛 url', 'background: #332167; color: #b3d1f6; font-size: 11px', url);
    return url;
    // const reader = new FileReader();
    // reader.onloadend = () => setPreviews([...previews, reader.result]);
    // reader.readAsDataURL(file);
  };

  const processFiles = (files) => {
    const filesList = Object.values(files);
    if (filesList.length > 0) {
      const filePreviews = filesList.map((file) => getPreview(file));
      onFilesAdded(filesList, filePreviews);
      setPreviews([...previews, ...filePreviews]);
    }
  };

  const handleDropzoneClick = () => {
    fileInputRef.current.click();
  };

  const handleOnFilesAdded = (ev) => {
    const { files } = ev.target;
    processFiles(files);
  };

  const handleOnDragOver = (ev) => {
    ev.preventDefault();
    setHighlight(true);
  };

  const handleOnDragLeave = () => setHighlight(false);

  const handleOnDrop = (ev) => {
    ev.preventDefault();
    const { files } = ev.dataTransfer;
    processFiles(files);
    setHighlight(false);
  };

  useEffect(() => {
    if (cleanPreviews) setPreviews([]);
  }, [cleanPreviews]);

  return (
    <StyledDropzone
      onClick={handleDropzoneClick}
      onDragOver={handleOnDragOver}
      onDragLeave={handleOnDragLeave}
      onDrop={handleOnDrop}
      highlight={highlight}
      preview={previews[0]}
    >
      {previews.length === 0 && <Label>{children}</Label>}
      <StyledInput
        type="file"
        ref={ref => { fileInputRef.current = ref; }}
        onChange={handleOnFilesAdded}
        multiple={multiple}
      />
    </StyledDropzone>
  );
};

export default Dropzone;
