import React, { useEffect, useState, useCallback } from 'react';
import SEO from '../components/Seo';
import styled from 'styled-components';
import breakpoints from '../styles/breakpoints';
import usePhotos from '../hooks/usePhotos';
import FlexLayout from '../components/FlexLayout';
import { listPhotos, addPhoto, editPhoto, removePhoto, listPhotosWithSubs } from '../api/Photos';
import { listSubPhotos, addSubPhoto, editSubPhoto, removeSubPhoto } from '../api/SubPhotos';

import Dialog from '../components/Dialog';
import AddPhoto from '../components/AddPhoto';
import PhotoItem from '../components/PhotoItem';
import { LOCATIONS } from '../scripts/constants';

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 56px;

  ${breakpoints.desktop} {
    display: flex;
    align-content: flex-start;
    justify-content: flex-start;
    grid-column: 1 / span 12;

    width: 100%;
    margin-bottom: 0px;
  }
`;

const TextStyles = `
  font-family: BodoniSvtyTwoSCITCTT-Book;
  font-size: 16px;
  font-weight: normal;
  color: #000000;
  letter-spacing: 2px;
`;

const Title = styled.h2`
  ${TextStyles}
  margin: 20px 0;
`;

const LongCard = styled.div`
  display: flex;
  border-top: 1px solid #bbbbbb;
  overflow-x: auto;
`;

const AdminPage = () => {
  const { images, setImages } = usePhotos();
  const [showDialog, setShowDialog] = useState({ show: false, photoId: 0 });

  const handleOnSubmit = async (formData) => {
    const res = await addPhoto(formData);
    setImages([...images, res]);
  };

  const handleOnSubmitSubPhoto = async (formData, imgId) => {
    formData.set('photoId', imgId);
    const res = await addSubPhoto(formData);
    const newImages = [...images];
    const foundImage = newImages.find((img) => img.id === imgId);
    console.log('%c🐛 foundImage', 'background: #332167; color: #b3d1f6; font-size: 11px', foundImage);
    if (!foundImage.SubPhotos) foundImage.SubPhotos = [];
    foundImage.SubPhotos.push(res);
    console.log('%c🐛 newImages', 'background: #332167; color: #b3d1f6; font-size: 11px', newImages);
    setImages([...newImages]);
  };

  const handleOnDeletePhoto = (photoId) => {
    setShowDialog({ show: true, photoId });
  };

  const handleOnAcceptDeletePhoto = () => {
    const newImages = images.filter(img => img.id !== showDialog.photoId);
    setImages([...newImages]);
    removePhoto(showDialog.photoId);
    setShowDialog({ show: false });
  };

  return (
    <FlexLayout>
      <SEO title="Admin" navigateTo="/admin" />
      <Title>Fotos:</Title>
      <Dialog
        show={showDialog.show}
        message="¿Seguro que quieres borrar esta foto?"
        onCancel={() => setShowDialog({ show: false })}
        onAccept={handleOnAcceptDeletePhoto}
      />
      <CardContainer>
        <AddPhoto type="photo" placeholder="Subir foto" onSubmit={handleOnSubmit} />
        {images?.map((img) => (
          <LongCard>
            <PhotoItem
              key={img.id}
              data={img}
              onEditPhoto={editPhoto}
              onDelete={handleOnDeletePhoto}
            />
            {img?.SubPhotos?.map(sub => (
              <PhotoItem
                key={sub.id}
                data={sub}
                onEditPhoto={editPhoto}
                onDelete={handleOnDeletePhoto}
                small
              />
            ))}
            <AddPhoto
              type="subphoto"
              placeholder="Subir subfoto"
              onSubmit={(data) => handleOnSubmitSubPhoto(data, img.id)}
              small
            />
          </LongCard>
        ))}
      </CardContainer>
    </FlexLayout>
  );
};

export default AdminPage;
